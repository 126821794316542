.table>thead>tr>th {
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 0px;
    padding: 18px 10px;
    text-align: left;
}

.balance-primary {
    color: #00b143;
}

.balance-secondary {
    color: rgb(206, 6, 16);
}

.balance-default {
    color: #0a2759;
}

.status-success {
    color: #00b143;
}

.status-pending {
    color: rgb(19, 6, 206);
}

.status-cancelled {
    color: rgb(255, 0, 0);
}

.status-failed {
    color: rgb(255, 0, 0);
}

#tbl-tranactionlist {
    font-size: 13px;
}

.status-txt {
    text-transform: capitalize;
}

.st-tabs a {
    font-size: 13px;
    font-style: initial;
    text-transform: uppercase;
    font-weight: 500;
    color: #7e93ae;
}

.table-bordered {
    font-size: 13px;
}
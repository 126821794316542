#error-main .error-icon{
    font-size: 60px;
    color: #9b9c9e;

}
#error-main .error-title{   
    color: #9b9c9e;
}
#error-main .error-description{ 
    color: #9b9c9e;
}
.sticky-top {
    z-index: 100;
}

.login-register-btn {
    max-width: 80px;
    width: 80px;
    padding: 15px 10px;
}

.new-bg {
    border-radius: 15px 50px 30px 5px;
    background-color: #000;
    max-width: 8% !important;
}